import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="E" transform="translate(31.000000, 26.000000)">
        <path
          d="M34.0352 39.8984C33.1758 40.6992 32.1699 41.4023 31.0176 42.0078C29.8652 42.5938 28.6543 43.0918 27.3848 43.502C26.1348 43.9121 24.8652 44.2148 23.5762 44.4102C22.2871 44.625 21.0859 44.7324 19.9727 44.7324C17.0039 44.7324 14.3379 44.2344 11.9746 43.2383C9.61133 42.2227 7.59961 40.7871 5.93945 38.9316C4.2793 37.0566 3 34.791 2.10156 32.1348C1.22266 29.4785 0.783203 26.5 0.783203 23.1992C0.783203 19.4297 1.33008 16.1289 2.42383 13.2969C3.51758 10.4453 4.97266 8.07227 6.78906 6.17773C8.625 4.2832 10.7344 2.86719 13.1172 1.92969C15.5 0.972656 17.9805 0.494141 20.5586 0.494141C21.6133 0.494141 22.6875 0.5625 23.7812 0.699219C24.875 0.835938 25.9688 1.06055 27.0625 1.37305C28.1758 1.68555 29.2793 2.10547 30.373 2.63281C31.4668 3.16016 32.541 3.81445 33.5957 4.5957L31.8379 9.86914C30.9199 9.04883 29.9824 8.35547 29.0254 7.78906C28.0684 7.22266 27.1113 6.77344 26.1543 6.44141C25.1973 6.08984 24.2402 5.8457 23.2832 5.70898C22.3457 5.55273 21.4375 5.47461 20.5586 5.47461C18.5078 5.47461 16.623 5.83594 14.9043 6.55859C13.1855 7.26172 11.7012 8.32617 10.4512 9.75195C9.2207 11.1777 8.25391 12.9551 7.55078 15.084C6.84766 17.1934 6.49609 19.6543 6.49609 22.4668C6.49609 25.1035 6.83789 27.4766 7.52148 29.5859C8.22461 31.6953 9.18164 33.4922 10.3926 34.9766C11.623 36.4609 13.0781 37.6035 14.7578 38.4043C16.4375 39.2051 18.2734 39.6055 20.2656 39.6055C21.3789 39.6055 22.502 39.4785 23.6348 39.2246C24.7871 38.9707 25.9102 38.6094 27.0039 38.1406C28.0977 37.6523 29.1426 37.0762 30.1387 36.4121C31.1348 35.748 32.043 35.0059 32.8633 34.1855L34.0352 39.8984Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
